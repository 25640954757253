import React from 'react'
import './header.css'
import ResumeHeaderContact from './Contact/contact'
import ResumeHeaderSocial from './Social/social'
import ResumeHeaderSummary from './Summary/summary'

const ResumeHeader = ({ headerData }) => (
  <section className="resume-header">
    <header>
      <span className="resume-header-name">{headerData.name}</span>
    </header>
    <ResumeHeaderContact contact={headerData.contact} preferredName={headerData.preferredName}></ResumeHeaderContact>
    <ResumeHeaderSummary summary={headerData.summary}></ResumeHeaderSummary>
    <ResumeHeaderSocial
      social={headerData.social}
      preferredName={headerData.preferredName}
    ></ResumeHeaderSocial>
  </section>
)

export default ResumeHeader
