import React from 'react'
import awsSAACertificationBadge from '../../../../../assets/images/AWS-SolArchitect-Associate.png'
import azureFundamentalsCertifcationBadge from '../../../../../assets/images/azure-fundamentals-600x600.png'
import azureDataFundamentalsCertifcationBadge from '../../../../../assets/images/azure-data-fundamentals-600x600.png'

import './badges.css'

const map = {
  'aws-sa': awsSAACertificationBadge,
  'azure-fu': azureFundamentalsCertifcationBadge,
  'azure-d-fu': azureDataFundamentalsCertifcationBadge,
}

const Badge = ({ badge }) => (
  <a
    href={badge.verificationUrl}
    target="_blank"
    rel="noreferrer"
    name={badge.name}
    title={'See ' + badge.name}
  >
    <figure>
      <img
        className={'interactive-image badge-image'}
        src={map[badge.id]}
        alt={badge.name}
        height="80px"
        width="80px"
      />
    </figure>
  </a>
)

const Badges = ({ badges }) => (
  <section className="badges">
    {badges.map((badge, index) => (
      <Badge badge={badge} key={index}></Badge>
    ))}
  </section>
)

export default Badges
