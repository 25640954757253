import React from 'react'
import StyledBullet from '../../../StyledBullet/styled-bullet'

import './education.css'

const EducationItem = ({ education }) => (
  <section className="education-item">
    <div className="education-item-qualification">{education.degree}</div>
    <div className="education-item-institute">{education.institute}</div>
    <div>
      <span>Graduated in {education.graduatedOn}</span>{' '}
      {education.percentage && <span> with {education.percentage}% </span>}
    </div>
  </section>
)

const Education = ({ education }) => (
  <section className="education">
    <header className="education-header section-header">
      <StyledBullet></StyledBullet>
      Education
    </header>
    {education.map((edu, index) => (
      <EducationItem education={edu} key={index}></EducationItem>
    ))}
  </section>
)

export default Education
