import React from 'react'
import Stars from '../../../Stars/stars'
import StyledBullet from '../../../StyledBullet/styled-bullet'
import './skills.css'

const Skills = ({ skills }) => (
  <section className="skills">
    <header className="skills-header section-header">
      <StyledBullet></StyledBullet>
      Professional Skills
    </header>
    <table className="skills-list">
      <tbody>
        {skills.map((skill, index) => (
          <tr key={index}>
            <td className="skills-col-1">{skill.name + ': '}</td>
            <td className="skills-col-2">
              <Stars number={skill.proficiency}></Stars>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </section>
)

export default Skills
