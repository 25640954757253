import React from 'react'
import './summary.css'

const ResumeHeaderSummary = ({ summary }) =>
  summary &&
  summary.length > 0 && (
    <section className="header-summary">
      <ul className="header-summary-list">
        {summary.map((summary, index) => (
          <li className="header-summary-list-item" key={index}>
            {summary}
          </li>
        ))}
      </ul>
    </section>
  )

export default ResumeHeaderSummary
