import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBurn } from '@fortawesome/free-solid-svg-icons'

import './styled-bullet.css'

const StyledBullet = () => (
  <FontAwesomeIcon
    icon={faBurn}
    transform={{ rotate: 42 }}
    size={'lg'}
    className="styled-bullet"
  ></FontAwesomeIcon>
)

export default StyledBullet
