import React from 'react'
import ProjectSummary from '../ProjectSummary/project-summary'
import './projects-summary.css'

const ProjectsSummary = ({ projects }) => (
  <section className="projects-summary">
    <header className="projects-summary-header">Projects:</header>
    {projects.map(project => (
      <ProjectSummary project={project}></ProjectSummary>
    ))}
  </section>
)

export default ProjectsSummary
