import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'

import './contact.css'

const ResumeHeaderContact = ({ contact, preferredName }) =>
  contact && (
    <section className="resume-contact">
      {contact.email && (
        <span className="contact-email">
          <FontAwesomeIcon size="lg" icon={faEnvelope}></FontAwesomeIcon>{' '}
          <a className="url" href={"mailto:" + contact.email}
            title={"Send an email to " + preferredName}
          >{contact.email}</a>
        </span>
      )}
      {contact.mobile && (
        <span className="contact-mobile">
          <FontAwesomeIcon size="lg" flip="horizontal" icon={faPhone}></FontAwesomeIcon>{' '}
          <a className="url" href={"tel:" + contact.mobile}
            title={"Call " + preferredName}
          >{contact.mobile}</a>
        </span>
      )}
      {contact.portfolioUrl && (
        <span className="contact-portfolioUrl">
          <FontAwesomeIcon icon={faExternalLinkSquareAlt}></FontAwesomeIcon>{' '}
          <a
            className="url"
            target="_blank"
            rel="noreferrer"
            href={contact.portfolioUrl}
            title={'Visit ' + preferredName + "'s portfolio"}
          >
            {contact.portfolioUrl}
          </a>
        </span>
      )}
    </section>
  )

export default ResumeHeaderContact
