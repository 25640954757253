import React from 'react'
import { Fragment } from 'react'
import EmploymentHistory from './EmploymentHistory/employment-history'
import './professional-summary.css'

const ProfessionalSummary = ({ professionalSummaryData }) => (
  <Fragment>
    <EmploymentHistory
      employmentData={professionalSummaryData.employmentHistory}
    ></EmploymentHistory>
  </Fragment>
)

export default ProfessionalSummary
