import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faStackOverflow } from '@fortawesome/free-brands-svg-icons'

import './social.css'

const ResumeHeaderSocial = ({ social, preferredName }) =>
  social && (
    <section className="social">
      {social.linkedInProfileUrl && (
        <span className="social-linkedin">
          <FontAwesomeIcon size="lg" icon={faLinkedin}></FontAwesomeIcon>{' '}
          <a
            className="url"
            target="_blank"
            rel="noreferrer"
            href={social.linkedInProfileUrl}
            title={'See ' + preferredName + "'s Linkedin profile"}
          >
            {social.linkedInProfileUrl}
          </a>
        </span>
      )}
      {social.stackOverflowProfileUrl && (
        <span className="social-stack-overflow">
          <FontAwesomeIcon size="lg" icon={faStackOverflow}></FontAwesomeIcon>{' '}
          <a
            className="url"
            target="_blank"
            rel="noreferrer"
            href={social.stackOverflowProfileUrl}
            title={'See ' + preferredName + "'s StackOverflow profile"}
          >
            {social.stackOverflowProfileUrl}
          </a>
        </span>
      )}
    </section>
  )

export default ResumeHeaderSocial
