import React from 'react'
import StyledBullet from '../../../StyledBullet/styled-bullet'

import './achievements.css'

const Achievements = ({ achievements }) => (
  <section className="achievements">
    <header className="achievements-header section-header">
      <StyledBullet></StyledBullet>
      Achievements
    </header>
    <ul className="achievements-list">
      {achievements.map((achievement, index) => (
        <li key={index}>{achievement}</li>
      ))}
    </ul>
  </section>
)

export default Achievements
