import React from 'react'
import { Fragment } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons'

const getFullStars = number => parseInt(number)
const getEmptyStars = number => 5 - Math.ceil(number)
const getHalfStars = number => 5 - getFullStars(number) - getEmptyStars(number)

const Stars = ({ number }) => (
  <Fragment>
    {getFullStars(number) > 0 &&
      [...Array(getFullStars(number))].map((e, i) => (
        <FontAwesomeIcon icon={faStar} key={i}></FontAwesomeIcon>
      ))}
    {getHalfStars(number) > 0 &&
      [...Array(getHalfStars(number))].map((e, i) => (
        <FontAwesomeIcon icon={faStarHalfAlt} key={10 + i}></FontAwesomeIcon>
      ))}
    {getEmptyStars(number) > 0 &&
      [...Array(getEmptyStars(number))].map((e, i) => (
        <FontAwesomeIcon icon={regularStar} key={20 + i}></FontAwesomeIcon>
      ))}
  </Fragment>
)

export default Stars
