import React from 'react'
import StyledBullet from '../../../StyledBullet/styled-bullet'

import './interests.css'

const Interests = ({ interests }) => (
  <section className="interests">
    <header className="interests-header section-header">
      <StyledBullet></StyledBullet>
      Interests
    </header>
    <ul className="interest-list">
      {interests.map((interest, index) => (
        <li key={index}>{interest}</li>
      ))}
    </ul>
  </section>
)

export default Interests
