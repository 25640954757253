import React from 'react'
import ProjectsSummary from '../ProjectsSummary/projects-summary'
import './employment-item.css'

const EmploymentItem = ({ employment }) =>
  employment && (
    <section className="employment-item">
      <header className="employment-item-header">
        <div>
          <span className="employment-item-job-title">{employment.title}</span>{' '}
          at{' '}
          <span className="employment-item-organization">
            {employment.organization}
          </span>
        </div>
        <div className="employment-item-date">
          <span data-section="employment">
            {employment.duration.start.month}
          </span>{' '}
          <span data-section="employment">
            {employment.duration.start.year}
          </span>{' '}
          -{' '}
          <span data-section="employment">{employment.duration.end.month}</span>{' '}
          <span data-section="employment">{employment.duration.end.year}</span>
        </div>
      </header>
      <main className="employment-item-main">
        {employment.summary && (
          <div
            className="employment-item-summary"
            dangerouslySetInnerHTML={{ __html: employment.summary }}
          ></div>
        )}
        {employment.projects && (
          <ProjectsSummary projects={employment.projects}></ProjectsSummary>
        )}
      </main>
    </section>
  )

export default EmploymentItem
