import React from 'react'
import './project-summary.css'

const ProjectSummary = ({ project }) =>
  project && (
    <section className="project-sumary">
      <div className="project_detail">
        <div>
          {project.for && <span class="project_for">{project.for}</span>}
          <span class="project_name">{project.name}</span>
          <span class="project_role">{project.role}</span>
          <span class="project_duration">{project.duration}</span>
        </div>
        <span class="project_description">
          <p> {project.description} </p>
        </span>
      </div>
    </section>
  )

export default ProjectSummary
