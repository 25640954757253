import React from 'react'
import StyledBullet from '../../../StyledBullet/styled-bullet'
import EmploymentItem from '../EmploymentItem/employment-item'
import './employment-history.css'

const EmploymentHistory = ({ employmentData }) =>
  employmentData && (
    <section className="employment-history">
      <header className="employment-history-header section-header">
        <StyledBullet></StyledBullet>
        Employment Timeline
      </header>
      {employmentData.map((employment, index) => (
        <EmploymentItem employment={employment} key={index}></EmploymentItem>
      ))}
    </section>
  )

export default EmploymentHistory
