import React, { Fragment } from 'react'
import DefaultLayout from '../components/DefaultLayout/default-layout'
import DownloadResume from '../components/Resume/DownloadResume/download-resume'

import ResumeFooter from '../components/Resume/Footer/footer'
import ResumeHeader from '../components/Resume/Header/header'
import Main from '../components/Resume/Main/main'
import { resumeData } from '../data/resume/resumeData'

import './resume.css'

const ResumePage = () => (
  <DefaultLayout titleSuffix="Resume">
    <Fragment>
      <section className="resume-content">
        <ResumeHeader headerData={resumeData.header} />
        <Main
          otherRelevantData={resumeData.otherRelevantData}
          professionalSummaryData={resumeData.professionalSummary}
        />
        <ResumeFooter footerData={resumeData.footer} />
      </section>
      <DownloadResume></DownloadResume>
    </Fragment>
  </DefaultLayout>
)
export default ResumePage
