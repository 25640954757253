import React from 'react'
import './main.css'
import OtherRelevantData from './OtherRelevantData/other-relevant-data'
import ProfessionalSummary from './ProfessionalSummary/professional-summary'

const Main = ({ otherRelevantData, professionalSummaryData }) => (
  <section className="main">
    <section className="other-relevant-data">
      <OtherRelevantData
        otherRelevantData={otherRelevantData}
      ></OtherRelevantData>
    </section>
    <section className="professional-summary">
      <ProfessionalSummary
        professionalSummaryData={professionalSummaryData}
      ></ProfessionalSummary>
    </section>
  </section>
)

export default Main
