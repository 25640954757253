import React from 'react'

import pdfDownloadImage from '../../../assets/icons/pdf-download.png'

import './download-resume.css'

const DownloadResume = () => (
  <div className="download-resume-container">
    <a
      href="/static/Girija-Shankar-Swain-Resume.pdf"
      rel="noreferrer"
      name="Girija's Resume PDF"
      title="Download Girija's Resume PDF"
      className="download-resume-link"
      download
    >
      <span className="download-text">Download</span>
      <img
        src={pdfDownloadImage}
        alt="Download PDF"
        className="download-pdf-image"
      ></img>
    </a>
  </div>
)

export default DownloadResume
