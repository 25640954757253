export const resumeData = {
  header: {
    name: 'Girija Swain',
    preferredName: 'Girija',
    summary: [
      '12+ years of professional experience in solving business problems using right technologies and people skills',
      '9+ years of professional working experience with Fin-Techs (Banking and Insurance)',
      'Expertise in Cloud Native Event Driven Microservices Architecture, Domain Driven Design, Cloud Solution Architecture',
      'AWS Certified Solutions Architect - Associate (since Jul 2018)',
      'Strong programming skills in Java and JavaScript',
    ],
    social: {
      linkedInProfileUrl: 'https://www.linkedin.com/in/gsswain',
      stackOverflowProfileUrl:
        'https://stackoverflow.com/users/4601120/gsswain',
    },
    contact: {
      email: 'contact@gsswain.com',
      portfolioUrl: 'https://gsswain.com',
      mobile: '',
    },
  },
  professionalSummary: {
    employmentHistory: [
      {
        organization: 'Macquarie Group, Sydney',
        title: 'Lead Engineer',
        duration: {
          start: {
            month: 'Feb',
            year: '2022',
          },
          end: {
            month: 'Present',
            year: '',
          },
        },
        summary: `I'm contributing to the solution and architecture of the entitlements platform (on top of GCP),
         which powers authorisation for electronic banking, mobile banking 
         and consents for open banking - Consumer Data Right (CDR).
         Below are some of the highlights: 
        <ul>
        <li> Created solution for onboarding business banking customers to the new in-house entitlements platform. </li>
        <li> Led the migration of millions of customers and their account level access permissions to the new entitlements platform. </li>
        <li> Improved engineering practices and developer on-boarding process. </li>
        <li> Designed and built solution to manage entitlements from the CRM platform. </li>
        <li> Designed and built solution for CDR consent lifecycle management, consent notifications. </li>
        <li> Conduct code reviews, design and architecture discussions, collaborate across teams and mentor the team. </li>
      </ul>
    `,
      },

      {
        organization: 'Aegon Life Insurance Company (ALIC), India',
        title: 'Principal Engineer',
        duration: {
          start: {
            month: 'Feb',
            year: '2019',
          },
          end: {
            month: 'Dec',
            year: '2020',
          },
        },
        summary: `I architected and built a cloud native policy administration solution on AWS with a lot of agility in a startup like environment. Below are some of the highlights:
            <ul>
              <li> Designed and built the event-driven cloud native platform from scratch (using SpringBoot 2.X and Spring cloud AWS) which addressed cross-cutting concerns like monitoring, observability, resilience, distributed caching etc. </li>
              <li> Designed and built a multi-tenant policy administration system with B2B insurance sales, which allowed onboarding of e-commerce platforms like PayTM, MobiKwik, Flipkart etc. </li>
              <li> Built various patterns around the business process orchestrator (Camunda) for asynchronous execution which became a Solution Building Block.</li>
              <li> Initiated and facilitated Architecture Review Board.</li>
              <li> Collaborated with the Aegon Europe team on designing and building Knowledge Management System and Conversational engine (Chatbot). </li>
              <li> Collaborated with ThoughtWorks (one of our consulting partners) to improve our engineering practices, processes and customer experience. </li>
            </ul>
          `,
      },
      // {
      //   organization: 'T.I.A.A. Global Business Services (TIAA GBS), India',
      //   title: 'Associate',
      //   duration: {
      //     start: {
      //       month: 'Jul',
      //       year: '2017',
      //     },
      //     end: {
      //       month: 'Oct',
      //       year: '2018',
      //     },
      //   },
      // },
      // {
      //   organization: 'J.P. Morgan Services India (JPMC), India',
      //   title: 'Associate',
      //   duration: {
      //     start: {
      //       month: 'Feb',
      //       year: '2016',
      //     },
      //     end: {
      //       month: 'May',
      //       year: '2017',
      //     },
      //   },
      // },
      // {
      //   organization: 'L&T Infotech (LTI), India',
      //   title: 'Software Associate',
      //   duration: {
      //     start: {
      //       month: 'Dec',
      //       year: '2013',
      //     },
      //     end: {
      //       month: 'Feb',
      //       year: '2016',
      //     },
      //   },
      // },
      // {
      //   organization: 'Tata Consultancy Services (TCS), India',
      //   title: 'Systems Engineer',
      //   duration: {
      //     start: {
      //       month: 'Aug',
      //       year: '2010',
      //     },
      //     end: {
      //       month: 'Dec',
      //       year: '2013',
      //     },
      //   },
      // },
    ],
  },
  otherRelevantData: {
    badges: [
      {
        id: 'aws-sa',
        name: "Girija's AWS Solution Architect badge",
        verificationUrl:
          'https://www.credly.com/badges/c927fef9-e5ee-4e73-a1c5-dfbb5dd31d40/public_url',
        period: {
          from: 'Jul 2018',
          validTill: 'Feb 2025',
        },
      },
      {
        id: 'azure-fu',
        name: "Girija's Azure Fundamentals badge",
        verificationUrl:
          'https://www.credly.com/badges/6f8283a0-a895-4d7c-8d9a-5368d1433248/public_url',
      },
    ],
    skills: [
      {
        name: 'Microservices',
        proficiency: 4,
      },
      {
        name: 'Serverless',
        proficiency: 4,
      },
      {
        name: 'AWS - Solution Architecture',
        proficiency: 4,
      },
      {
        name: 'Java',
        proficiency: 4,
      },
      {
        name: 'JavaScript',
        proficiency: 4,
      },
      ,
    ],
    education: [
      {
        institute: 'Indira Gandhi National Open University',
        degree: 'Master of Computer Applications',
        //   percentage: '63',
        graduatedOn: 'June 2016',
      },
      {
        institute: 'Berhampur University',
        degree: 'Bachelors in Computer Applications',
        //   percentage: '83',
        graduatedOn: 'June 2010',
      },
    ],
    achievements: [
      '5K+ reputation on Stack Overflow',
      'Annual Team Performance Award (2019-2020)',
      'Quarterly Team Award (2019)',
      'Quarterly Individual Performance Award (2018)',
      'Team Performance Award (2015)',
      // 'College Topper Award',
    ],
    languages: [
      {
        name: 'English',
        proficiency: 5,
      },
      {
        name: 'Odia',
        proficiency: 5,
      },
      {
        name: 'Hindi',
        proficiency: 4,
      },
    ],
    interests: [
      'Playing Cricket',
      'Travelling & Capturing Landscapes',
      'Riding bikes',
    ],
  },
  footer: {
    portfolioUrl: 'https://gsswain.com',
  },
}
